import { Check, Close, Edit } from "@mui/icons-material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import {
  AlertTitle,
  Box,
  Container,
  Grid,
  IconButton,
  Tab,
  TextField,
  Typography
} from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"
import { useAtom, useSetAtom } from "jotai"
import { useSnackbar } from "notistack"
import type { FC, SyntheticEvent } from "react"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import {
  ProjectChangeStatusBox,
  ProjectCommentListView,
  ProjectSummaryView
} from "src/features/project"
import { ApiError } from "src/shared/client"
import {
  Alert,
  ChipForTooltip,
  LoadingContainer,
  Tooltip
} from "src/shared/components"
import {
  projectDataAtom,
  projectIdAtom,
  projectStatusChangeListAtom,
  updateProjectDataAtom
} from "src/shared/stores"

type ProjectDetailsProps = {
  open: boolean
  handleDrawerClose: () => void
}

const ProjectDetails: FC<ProjectDetailsProps> = ({
  open,
  handleDrawerClose
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "projectDetails"
  })

  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { projectId } = useParams()
  const [searchParams] = useSearchParams()

  const setProjectId = useSetAtom(projectIdAtom)

  const [{ mutate: updateProjectData, isPending: isUpdatingProjectData }] =
    useAtom(updateProjectDataAtom)
  const [
    {
      data: projectData,
      error: projectDataFetchingError,
      isFetching: isFetchingProjectData,
      isPending,
      isError: isErrorFetchingProjectData,
      refetch: refetchProjectData
    }
  ] = useAtom(projectDataAtom)

  const [
    {
      data: projectStatusAndCommentData,
      isError: isErrorFetchingProjectStatusAndCommentData
    }
  ] = useAtom(projectStatusChangeListAtom)

  const projectStatus = projectData?.status as ProjectStatus

  const [newProjectName, setNewProjectName] = useState<string | null>(null)
  const [isEditingProjectName, setIsEditingProjectName] = useState(false)

  useEffect(() => {
    if (projectId && !searchParams.get("tab")) {
      searchParams.set("tab", "1")

      navigate(`/projects/${projectId}?${searchParams.toString()}`)
    }
  }, [projectId, navigate, searchParams])

  const handleTabChange = (_event: SyntheticEvent, newTabPage: string) => {
    searchParams.set("tab", newTabPage)
    navigate(`/projects/${projectId}?${searchParams.toString()}`)
  }

  const handleProjectNameUpdate = (
    projectData: ProjectRecord,
    newProjectName: string | null
  ) => {
    if (newProjectName !== null && newProjectName !== "") {
      updateProjectData(
        { ...projectData, name: newProjectName },
        {
          onSuccess: async () => {
            await refetchProjectData()
            queryClient.invalidateQueries({
              queryKey: ["projectListData"],
              refetchType: "active"
            })
            setIsEditingProjectName(false)
            enqueueSnackbar({
              variant: "success",
              message: t("summaryTab.projectDataUpdateSuccessSnackbar")
            })
          },
          onError: async (error) => {
            const response: ServiceError = await error.errorResponse.json()

            enqueueSnackbar({
              variant: "detailedSnackbar",
              message: t(response.code, { keyPrefix: "errorCodes" }),
              details: response.message,
              autoHideDuration: null
            })

            setIsEditingProjectName(false)
          }
        }
      )
    } else {
      setIsEditingProjectName(false)
    }
  }

  const handleCloseButtonClick = useCallback(() => {
    setProjectId(undefined)
    searchParams.delete("tab")

    handleDrawerClose()
    navigate("/projects")
  }, [handleDrawerClose, navigate, searchParams, setProjectId])

  useEffect(() => {
    if (projectDataFetchingError) {
      const enqueueErrorSnackbar = async (error: ApiError) => {
        const response: ServiceError = await error.errorResponse.json()

        enqueueSnackbar({
          variant: "detailedSnackbar",
          message: t(response.code, { keyPrefix: "errorCodes" }),
          details: response.message,
          autoHideDuration: null
        })
      }

      enqueueErrorSnackbar(projectDataFetchingError)
    }
  }, [enqueueSnackbar, projectDataFetchingError, t])

  // TODO: Put it into a global function

  useEffect(() => {
    if (!open) return

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        const activeElement = document.activeElement

        if (!activeElement?.classList.contains("MuiInputBase-input")) {
          handleCloseButtonClick()
        } else {
          setIsEditingProjectName(false)
          if (newProjectName) setNewProjectName(null)
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [handleCloseButtonClick, newProjectName, open])

  // TODO: Put it into a global function Global Loder?
  if (isPending) {
    return <LoadingContainer />
  }

  if (isErrorFetchingProjectData || !projectData) {
    return (
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        data-testid="projectDetailsError"
      >
        {t("projectDataFetchFailureAlert")}
      </Box>
    )
  }

  return (
    <Container data-testid="projectDetails" maxWidth={false}>
      <Box className="McpDrawerBox">
        <Grid container className="McpDrawerContent">
          <Grid xs={12} item container className="McpDrawerFixed">
            <Grid
              xs={12}
              item
              container
              alignItems="center"
              justifyContent="space-between"
              className="McpDrawerFixedSubheadline"
            >
              <Grid xs="auto" item>
                <Tooltip
                  text={t("projectStatus", { keyPrefix: "common" })}
                  className="noBorder"
                >
                  <span>
                    <ChipForTooltip
                      status={projectData.status}
                      pathname="projects"
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid
                xs="auto"
                item
                container
                spacing={1}
                justifyContent={"flex-end"}
              >
                <Grid item>
                  <Tooltip text={`${t("close", { keyPrefix: "common" })}`}>
                    <IconButton
                      onClick={handleCloseButtonClick}
                      className="projectDrawerCloseButton"
                      data-testid="projectDrawerCloseButton"
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>

            <Grid xs={12} item container className="McpDrawerFixedHeadline">
              <Grid xs={12} item container gap={1}>
                {isEditingProjectName ? (
                  <>
                    <Grid
                      container
                      item
                      className="McpQuickEditInput"
                      columnSpacing={1}
                    >
                      <TextField
                        disabled={
                          isUpdatingProjectData || isFetchingProjectData
                        }
                        defaultValue={projectData.name}
                        variant="outlined"
                        size="small"
                        onChange={(e) => setNewProjectName(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault()
                            handleProjectNameUpdate(projectData, newProjectName)
                          }
                        }}
                        inputProps={{
                          autoFocus: true
                        }}
                      />
                      <Grid container item columnSpacing={1}>
                        <Grid item>
                          <Tooltip text={t("saveBtn", { keyPrefix: "common" })}>
                            <IconButton
                              disabled={
                                isUpdatingProjectData || isFetchingProjectData
                              }
                              onClick={() =>
                                handleProjectNameUpdate(
                                  projectData,
                                  newProjectName
                                )
                              }
                            >
                              <Check />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            text={t("cancelBtn", { keyPrefix: "common" })}
                          >
                            <IconButton
                              disabled={
                                isUpdatingProjectData || isFetchingProjectData
                              }
                              onClick={() => {
                                setIsEditingProjectName(false)
                                if (newProjectName) setNewProjectName(null)
                              }}
                            >
                              <Close />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    {/* EDIT ICON EXCLUDE IN STATUS READY_FOR_APPROVAL */}
                    {projectData.status !== "READY_FOR_APPROVAL" ? (
                      <>
                        <Tooltip
                          text={t("editText", {
                            projectName: projectData.name,
                            keyPrefix: "common"
                          })}
                          className="noBorder fullWidth"
                        >
                          <div>
                            <Typography
                              variant="h2"
                              className="McpQuickEditHeadline"
                              onClick={() => setIsEditingProjectName(true)} // Korrektur hier
                            >
                              {projectData.name}
                              <IconButton
                                onClick={() => setIsEditingProjectName(true)}
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </Typography>
                          </div>
                        </Tooltip>
                      </>
                    ) : (
                      <Typography variant="h2">{projectData.name}</Typography>
                    )}
                  </>
                )}
              </Grid>
            </Grid>

            {/* ALERT BOX ONLY IN WAITING_FOR_MERCHANT */}
            {projectData.status === "WAITING_FOR_MERCHANT" && (
              <Grid xs={12} item mb={2}>
                <Alert status={projectData.status}>
                  <AlertTitle>{t(`headlineAlert.${projectStatus}`)}</AlertTitle>
                </Alert>
              </Grid>
            )}
          </Grid>

          <Grid xs={12} item className="McpTabs">
            <TabContext value={searchParams.get("tab") ?? "1"}>
              <Box className="McpTabsContext">
                <TabList
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons={false}
                >
                  <Tab label={t("summaryTab.tabLabel")} value="1" />
                  <Tab label={t("commentsTab.tabLabel")} value="2" />
                </TabList>
              </Box>
              <TabPanel value="1" className="McpSummaryView">
                {<ProjectSummaryView projectData={projectData} />}
              </TabPanel>
              <TabPanel value="2" className="McpSummaryView">
                {isErrorFetchingProjectStatusAndCommentData ||
                !projectStatusAndCommentData ? (
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    data-testid="projectDetailsError"
                  >
                    {t("projectDataFetchFailureAlert")}
                  </Box>
                ) : (
                  <>
                    <ProjectCommentListView
                      projectData={projectData}
                      projectStatusAndCommentData={projectStatusAndCommentData}
                    />

                    {projectData.status === "WAITING_FOR_MERCHANT" && (
                      <ProjectChangeStatusBox />
                    )}
                  </>
                )}
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default ProjectDetails
