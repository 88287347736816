import { CssBaseline, ThemeProvider } from "@mui/material"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { Provider as JotaiProvider, useAtomValue } from "jotai"
import { queryClientAtom } from "jotai-tanstack-query"
import { SnackbarProvider } from "notistack"
import { ReactNode } from "react"
import { BrowserRouter } from "react-router-dom"
import { DetailedSnackbar } from "src/shared/components"
import { theme } from "src/shared/theming"

import useSnackbarAction from "./useSnackbarAction"

interface ProvidersProps {
  children: ReactNode
}

const Providers = ({ children }: ProvidersProps) => {
  const { action } = useSnackbarAction()
  const queryClient = useAtomValue(queryClientAtom) as unknown as QueryClient

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <JotaiProvider>
            <SnackbarProvider
              Components={{
                detailedSnackbar: DetailedSnackbar
              }}
              autoHideDuration={3000}
              preventDuplicate={true}
              action={action}
              maxSnack={15}
            >
              <CssBaseline />
              {children}
            </SnackbarProvider>
          </JotaiProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default Providers
