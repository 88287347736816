import { LinkProps } from "@mui/material"
import { forwardRef } from "react"
import { NavLink, NavLinkProps, useLocation } from "react-router-dom"

const Navlink = forwardRef<
  HTMLAnchorElement,
  NavLinkProps & LinkProps & { isNested: boolean }
>((props, ref) => {
  const { isNested, ...rest } = props
  const { pathname } = useLocation()

  const isNavlinkActive = () => {
    if (isNested) {
      return pathname === props.to ? true : false
    } else {
      //dashboard
      if (props.to === "/" && pathname === props.to) {
        return true
      }
      //other pages
      else if (props.to !== "/" && pathname.includes(props.to as string)) {
        return true
      } else {
        return false
      }
    }
  }

  return (
    <NavLink
      ref={ref}
      {...rest}
      className={() =>
        isNavlinkActive() ? props.className + " Mui-selected" : props.className
      }
      end
    />
  )
})

Navlink.displayName = "Navlink"
export default Navlink
