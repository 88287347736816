import {
  CheckCircleOutline,
  ErrorOutline,
  HighlightOff,
  WarningAmber
} from "@mui/icons-material"
import { AlertProps } from "@mui/material"

const useAlertConfig = () => {
  const alertColor: Record<
    OnboardingStatus | ProjectStatus,
    CustomAlertProps["color"]
  > = {
    APPROVED: "success",
    DECLINED: "error",
    WAITING_FOR_MERCHANT: "warning",
    READY_FOR_APPROVAL: "info",
    IN_PROGRESS: "info",
    NOT_YET_STARTED: "info"
  }

  const alertIcon: Record<
    OnboardingStatus | ProjectStatus,
    AlertProps["icon"]
  > = {
    APPROVED: <CheckCircleOutline />,
    DECLINED: <HighlightOff />,
    WAITING_FOR_MERCHANT: <WarningAmber />,
    READY_FOR_APPROVAL: <ErrorOutline />,
    IN_PROGRESS: <ErrorOutline />,
    NOT_YET_STARTED: <ErrorOutline />
  }
  return { alertIcon, alertColor }
}

export default useAlertConfig
