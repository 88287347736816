import { Box, CircularProgress, Container } from "@mui/material"

const LoadingContainer = () => (
  <Container>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
    >
      <CircularProgress />
    </Box>
  </Container>
)

export default LoadingContainer
