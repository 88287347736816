import {
  confirmResetPassword,
  confirmSignUp,
  fetchAuthSession,
  fetchUserAttributes,
  resendSignUpCode,
  resetPassword,
  signIn,
  signOut,
  signUp,
  updatePassword,
  updateUserAttribute
} from "@aws-amplify/auth"

import type {
  ChangePasswordParameters,
  ResendConfirmationCodeParameters,
  SendPasswordResetEmailParameters,
  SignInParameters,
  SignUpParameters,
  UpdatePasswordParameters,
  UpdateUserAttributeParameters,
  VerifyEmailAddressParameters
} from "./types"

const authSignUp = ({
  firstName,
  lastName,
  emailAddress,
  password,
  locale,
  privacyPolicyAccepted
}: SignUpParameters) =>
  signUp({
    username: emailAddress,
    password,
    options: {
      userAttributes: {
        email: emailAddress,
        given_name: firstName,
        family_name: lastName,
        locale,
        "custom:acceptPrivacyPolicy": privacyPolicyAccepted
      }
    }
  })

const authVerifyEmailAddress = ({
  emailAddress,
  verificationCode
}: VerifyEmailAddressParameters) =>
  confirmSignUp({ username: emailAddress, confirmationCode: verificationCode })

const authSignIn = ({ emailAddress, password }: SignInParameters) =>
  signIn({
    username: emailAddress,
    password
  })

const authSignOut = () => signOut()

const getUserAttributes = () => fetchUserAttributes()

const getCurrentSession = () => fetchAuthSession()

const getJwtToken = async () => (await getCurrentSession()).tokens?.idToken

const sendPasswordResetEmail = ({
  emailAddress
}: SendPasswordResetEmailParameters) =>
  resetPassword({ username: emailAddress })

const changePassword = ({
  emailAddress,
  verificationCode,
  newPassword
}: ChangePasswordParameters) =>
  confirmResetPassword({
    username: emailAddress,
    confirmationCode: verificationCode,
    newPassword
  })

const authUpdatePassword = ({
  currentPassword,
  newPassword
}: UpdatePasswordParameters) =>
  updatePassword({ oldPassword: currentPassword, newPassword })

const resendConfirmationCode = ({
  emailAddress
}: ResendConfirmationCodeParameters) =>
  resendSignUpCode({ username: emailAddress })

const authUpdateUserAttribute = ({
  attribute,
  value
}: UpdateUserAttributeParameters) =>
  updateUserAttribute({
    userAttribute: {
      attributeKey: attribute,
      value
    }
  })

const authenticationService = {
  signUp: authSignUp,
  verifyEmailAddress: authVerifyEmailAddress,
  signIn: authSignIn,
  signOut: authSignOut,
  getCurrentSession,
  sendPasswordResetEmail,
  changePassword,
  updatePassword: authUpdatePassword,
  updateUserAttribute: authUpdateUserAttribute,
  resendConfirmationCode,
  getJwtToken,
  getUserAttributes
}

export default authenticationService
