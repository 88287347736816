export const checkPasswordConditions = (password: string) => {
  const conditions: PasswordConditions = {
    specialCharacter: /[!@#$%^&*();,.?'`":{}|~+-/|<>]/.test(password),
    number: /\d/.test(password),
    minCharacters: password.length >= 8,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    whitespace: /^[\S]+.*[\S]+$/.test(password)
  }

  return conditions
}
