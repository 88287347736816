export const handleDate = (date?: string) =>
  !date
    ? "--"
    : `${new Date(date).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      })}`

export const handleTime = (date?: string) =>
  !date
    ? "--"
    : `${new Date(date).toLocaleTimeString("de-DE", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      })}`

export const normalizeWhitespace = (string: string) =>
  string.trim().replace(/[ \t\r\n\f\v\s]+/g, " ")
