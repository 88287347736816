import {
  KeyboardArrowRight,
  LocalPhone,
  MailOutline
} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Box, Divider, Grid, Typography } from "@mui/material"
import { useAtom } from "jotai"
import { useTranslation } from "react-i18next"
import { FormInputGroup } from "src/features/onboarding"
import { updateMerchantDataAtom } from "src/shared/stores"

const OtherContactView = ({
  handleUpdateMerchant
}: {
  handleUpdateMerchant: (e: React.BaseSyntheticEvent) => Promise<void>
}) => {
  const { t } = useTranslation("translation")
  const [{ isPending: isUpdatingMerchantData }] = useAtom(
    updateMerchantDataAtom
  )

  const departmentsInfo = {
    support: {
      title: t("accountPage.contact.otherTab.support"),
      email: {
        inputName: "support.email",
        labelName: t("onboardingPage.email")
      },
      phone: {
        inputName: "support.phone",
        labelName: t("onboardingPage.phone")
      }
    },
    finance: {
      title: t("accountPage.contact.otherTab.finance"),
      email: {
        inputName: "finance.email",
        labelName: t("onboardingPage.email")
      },
      phone: {
        inputName: "finance.phone",
        labelName: t("onboardingPage.phone")
      }
    },
    development: {
      title: t("accountPage.contact.otherTab.development"),
      email: {
        inputName: "development.email",
        labelName: t("onboardingPage.email")
      },
      phone: {
        inputName: "development.phone",
        labelName: t("onboardingPage.phone")
      }
    },
    techSupport: {
      title: t("accountPage.contact.otherTab.techSupport"),
      email: {
        inputName: "techSupport.email",
        labelName: t("onboardingPage.email")
      },
      phone: {
        inputName: "techSupport.phone",
        labelName: t("onboardingPage.phone")
      }
    }
  }

  return (
    <Box data-testid="merchantDepartmentsContainer" className="McpForm">
      <Grid container item xs={12} spacing={2}>
        <Grid
          item
          xs={12}
          lg={12}
          xl={2}
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <Typography variant="body1" sx={{ fontWeight: "bold", marginTop: 2 }}>
            {departmentsInfo.support.title}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} xl={5}>
          <FormInputGroup
            labelName={departmentsInfo.support.email.labelName}
            inputName={departmentsInfo.support.email.inputName}
            icon={<MailOutline />}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={5}>
          <FormInputGroup
            labelName={departmentsInfo.support.phone.labelName}
            inputName={departmentsInfo.support.phone.inputName}
            icon={<LocalPhone />}
          />
        </Grid>
      </Grid>

      <Divider variant="middle" />

      <Grid container item xs={12} spacing={2}>
        <Grid
          item
          xs={12}
          lg={12}
          xl={2}
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <Typography variant="body1" sx={{ fontWeight: "bold", marginTop: 2 }}>
            {departmentsInfo.finance.title}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} xl={5}>
          <FormInputGroup
            labelName={departmentsInfo.finance.email.labelName}
            inputName={departmentsInfo.finance.email.inputName}
            icon={<MailOutline />}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={5}>
          <FormInputGroup
            labelName={departmentsInfo.finance.phone.labelName}
            inputName={departmentsInfo.finance.phone.inputName}
            icon={<LocalPhone />}
          />
        </Grid>
      </Grid>

      <Divider variant="middle" />

      <Grid container item xs={12} spacing={2}>
        <Grid
          item
          xs={12}
          lg={12}
          xl={2}
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <Typography variant="body1" sx={{ fontWeight: "bold", marginTop: 2 }}>
            {departmentsInfo.development.title}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} xl={5}>
          <FormInputGroup
            labelName={departmentsInfo.development.email.labelName}
            inputName={departmentsInfo.development.email.inputName}
            icon={<MailOutline />}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={5}>
          <FormInputGroup
            labelName={departmentsInfo.development.phone.labelName}
            inputName={departmentsInfo.development.phone.inputName}
            icon={<LocalPhone />}
          />
        </Grid>
      </Grid>

      <Divider variant="middle" />

      <Grid container item xs={12} spacing={2}>
        <Grid
          item
          xs={12}
          lg={12}
          xl={2}
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <Typography variant="body1" sx={{ fontWeight: "bold", marginTop: 2 }}>
            {departmentsInfo.techSupport.title}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} xl={5}>
          <FormInputGroup
            labelName={departmentsInfo.techSupport.email.labelName}
            inputName={departmentsInfo.techSupport.email.inputName}
            icon={<MailOutline />}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={5}>
          <FormInputGroup
            labelName={departmentsInfo.techSupport.phone.labelName}
            inputName={departmentsInfo.techSupport.phone.inputName}
            icon={<LocalPhone />}
          />
        </Grid>
      </Grid>

      <Divider variant="middle" />

      <Grid item xs={12} className="McpButtons">
        <Grid>
          <LoadingButton
            loading={isUpdatingMerchantData}
            fullWidth
            variant="contained"
            color="primary"
            onClick={(e) => handleUpdateMerchant(e)}
          >
            {t("common.saveBtn")} <KeyboardArrowRight />
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export default OtherContactView
