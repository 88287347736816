const isInputValueEmptyString = (value?: string) => value?.trim() === ""

const isDepartmentDataEmpty = (departmentData?: Department) =>
  (departmentData?.email === null || departmentData?.email === "") &&
  (departmentData?.phone === null || departmentData?.phone === "")

const filterFormData = (formData: MerchantRecord) => {
  const filteredFormData: MerchantRecord = structuredClone(formData)

  if (isInputValueEmptyString(formData.companySize)) {
    filteredFormData.companySize = undefined
  }

  if (isInputValueEmptyString(formData.companyRevenue)) {
    filteredFormData.companyRevenue = undefined
  }

  if (isDepartmentDataEmpty(filteredFormData.support)) {
    filteredFormData.support = undefined
  }

  if (isDepartmentDataEmpty(filteredFormData.finance)) {
    filteredFormData.finance = undefined
  }

  if (isDepartmentDataEmpty(filteredFormData.development)) {
    filteredFormData.development = undefined
  }

  if (isDepartmentDataEmpty(filteredFormData.techSupport)) {
    filteredFormData.techSupport = undefined
  }

  return filteredFormData
}

export default filterFormData
