import { useAtom } from "jotai"
import { lazy, Suspense } from "react"
import { LoadingContainer } from "src/shared/components"
import { userAtom } from "src/shared/stores"

const UnAuthenticatedApp = lazy(() => import("./UnAuthenticatedApp"))
const AuthenticatedAppContainer = lazy(
  () => import("./AuthenticatedAppContainer")
)

const App = () => {
  const [{ isLoading: isDeterminingAuthenticationStatus, data: user }] =
    useAtom(userAtom)

  if (isDeterminingAuthenticationStatus) {
    return <LoadingContainer />
  }

  return (
    <Suspense fallback={<LoadingContainer />}>
      {user ? <AuthenticatedAppContainer /> : <UnAuthenticatedApp />}
    </Suspense>
  )
}

export default App
