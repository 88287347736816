import { fetchAuthSession } from "@aws-amplify/auth"
import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query"
import { ApiError, get as getClient, put as putClient } from "src/shared/client"
import { userAtom } from "src/shared/stores"

type UpdateMerchantDataInput = {
  updatedMerchantData: MerchantRecord
  signal?: AbortSignal
}

const MAX_RETRIES = 3

export const merchantDataAtom = atomWithQuery<MerchantRecord, ApiError>(
  (get) => {
    const { data: user } = get(userAtom)

    const getMerchantData = async (
      retryCount: number = 0
    ): Promise<MerchantRecord> => {
      try {
        const response = await (
          await getClient({
            path: `/merchant-service/merchants/${user?.merchantId}`
          })
        ).json()

        return response
      } catch (error) {
        if (error instanceof ApiError) {
          const response: ServiceError = await error.errorResponse
            .clone()
            .json()

          if (response.code === "UNAUTHORIZED" && retryCount < MAX_RETRIES) {
            await fetchAuthSession({ forceRefresh: true })

            return getMerchantData(retryCount + 1)
          } else {
            throw error
          }
        } else {
          throw error
        }
      }
    }

    return {
      queryKey: ["merchantData"],
      queryFn: async () => getMerchantData(),
      retry: false,
      enabled: !!user?.merchantId,
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  }
)

export const updateMerchantDataAtom = atomWithMutation<
  MerchantRecord,
  UpdateMerchantDataInput,
  ApiError
>((get) => {
  const { data: user } = get(userAtom)

  return {
    mutationFn: async ({
      updatedMerchantData,
      signal
    }): Promise<MerchantRecord> => {
      const response = await (
        await putClient({
          path: `/merchant-service/merchants/${user?.merchantId}`,
          body: updatedMerchantData,
          signal
        })
      ).json()

      return response
    },
    retry: false,
    staleTime: Infinity,
    refetchOnWindowFocus: false
  }
})
