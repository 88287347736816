import { Close } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { closeSnackbar, SnackbarKey } from "notistack"

const useSnackbarAction = () => {
  const action = (snackbarId: SnackbarKey) => (
    <IconButton color="inherit" onClick={() => closeSnackbar(snackbarId)}>
      <Close fontSize="small" />
    </IconButton>
  )

  return { action }
}

export default useSnackbarAction
