import { Chip as MUIChip } from "@mui/material"
import { forwardRef, RefObject } from "react"
import useChipConfig from "src/shared/components/api/useChipConfig"

type ChipLabelType = "onboardings" | "merchants" | "projects"

const Chip = forwardRef(
  (
    {
      status,
      pathname
    }: {
      status?: ProjectStatus | ProjectActivityStatus
      pathname?: ChipLabelType
    },
    ref:
      | ((instance: HTMLDivElement | null) => void)
      | RefObject<HTMLDivElement>
      | null
      | undefined
  ) => {
    const { chipColor, CHIP_DEFAULT_TEXT, chipLabel } = useChipConfig()

    return !status || !pathname ? (
      <MUIChip
        color="primary"
        label={CHIP_DEFAULT_TEXT}
        size="small"
        ref={ref}
      />
    ) : (
      <MUIChip
        ref={ref}
        color={chipColor[status]}
        label={chipLabel[status][pathname]}
        size="small"
      />
    )
  }
)
Chip.displayName = "Chip"
export default Chip
