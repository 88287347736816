import { Cancel, Close, ExpandMore } from "@mui/icons-material"
import {
  Box,
  Card,
  CardActions,
  Collapse,
  IconButton,
  Paper,
  Typography
} from "@mui/material"
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack"
import { forwardRef, useCallback, useState } from "react"

type DetailedSnackbarProps = CustomContentProps & {
  details?: string
}

const DetailedSnackbar = forwardRef<HTMLDivElement, DetailedSnackbarProps>(
  ({ id, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar()
    const [expanded, setExpanded] = useState<boolean>(false)

    const { message: errorMessage, details: errorDetails } = props

    const handleExpandButtonClick = useCallback(() => {
      setExpanded((expanded) => !expanded)
    }, [])

    const handleCloseButtonClick = useCallback(() => {
      closeSnackbar(id)
    }, [id, closeSnackbar])

    return (
      <SnackbarContent data-testid="detailedSnackbar" ref={ref}>
        <Box sx={{ minWidth: "344px" }}>
          <Card
            sx={{ width: "100%", color: "#fff", backgroundColor: "#d32f2f" }}
          >
            <CardActions
              sx={{
                padding: "8px 8px 8px 16px",
                justifyContent: "space-between"
              }}
            >
              <Typography
                sx={{ display: "flex", alignItems: "center", gap: 1, mr: 4 }}
                variant="body2"
              >
                <Cancel />
                {errorMessage}
              </Typography>
              <Box>
                <IconButton
                  sx={{
                    color: "#fff",
                    padding: "8px 8px",
                    transform: expanded ? "rotate(0deg)" : "rotate(180deg)"
                  }}
                  aria-label="Show more"
                  size="small"
                  onClick={handleExpandButtonClick}
                >
                  <ExpandMore />
                </IconButton>
                <IconButton
                  sx={{
                    color: "#fff",
                    padding: "8px 8px"
                  }}
                  aria-label="Close"
                  size="small"
                  onClick={handleCloseButtonClick}
                >
                  <Close fontSize="small" />
                </IconButton>
              </Box>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Paper sx={{ padding: 2 }}>
                <Typography variant="body2">{errorDetails}</Typography>
              </Paper>
            </Collapse>
          </Card>
        </Box>
      </SnackbarContent>
    )
  }
)

DetailedSnackbar.displayName = "Snackbar"

export default DetailedSnackbar
