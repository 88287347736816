import {
  KeyboardArrowRight,
  LocalPhone,
  MailOutline
} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Box, Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material"
import { useAtom } from "jotai"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormInputGroup, FormSelectGroup } from "src/features/onboarding"
import { updateMerchantDataAtom } from "src/shared/stores"

const DpoView = ({
  handleUpdateMerchant
}: {
  handleUpdateMerchant: (e: React.BaseSyntheticEvent) => Promise<void>
}) => {
  const { t } = useTranslation("translation")
  const [{ isPending: isUpdatingMerchantData }] = useAtom(
    updateMerchantDataAtom
  )
  const { control } = useFormContext()

  const dpoInfo = {
    dpoExternal: {
      inputName: "isDataProtectionOfficerExternal",
      labelName: t(
        "onboardingPage.contactFormData.dataProtectionOfficerCheckboxLabel"
      )
    },
    dpo: {
      title: {
        labelName: t("onboardingPage.title"),
        inputName: "dataProtectionOfficer.title",
        possibleSalutations: [
          {
            text: t("common.defaultSelect"),
            value: ""
          },
          {
            value: "MR",
            text: t("onboardingPage.maleSalutation")
          },
          {
            value: "MRS",
            text: t("onboardingPage.femaleSalutation")
          }
        ],
        rules: { required: t("onboardingPage.titleRequiredErrorMessage") }
      },
      firstName: {
        labelName: t("onboardingPage.firstName"),
        inputName: "dataProtectionOfficer.firstName",
        rules: {
          required: t("onboardingPage.firstNameRequiredErrorMessage")
        }
      },
      lastName: {
        labelName: t("onboardingPage.lastName"),
        inputName: "dataProtectionOfficer.lastName",
        rules: {
          required: t("onboardingPage.lastNameRequiredErrorMessage")
        }
      },
      email: {
        labelName: t("onboardingPage.email"),
        inputName: "dataProtectionOfficer.email",
        rules: {
          required: t("onboardingPage.emailRequiredErrorMessage")
        }
      },
      phone: {
        labelName: t("onboardingPage.phone"),
        inputName: "dataProtectionOfficer.phone"
      }
    }
  }

  return (
    <Box data-testid="merchantDpoContainer" className="McpForm">
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={dpoInfo.dpoExternal.inputName}
            render={({ field: { onChange, value } }) => (
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox onChange={onChange} checked={value} />}
                  label={dpoInfo.dpoExternal.labelName}
                />
              </FormGroup>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={2}
          xl={2}
          data-testid="dpoTitleInputGroup"
        >
          <FormSelectGroup
            labelName={dpoInfo.dpo.title.labelName}
            inputName={dpoInfo.dpo.title.inputName}
            selectItems={dpoInfo.dpo.title.possibleSalutations}
            isLabelRequired={true}
            rules={dpoInfo.dpo.title.rules}
          />
        </Grid>
        <Grid container item spacing={2} xs={12} md={12} lg={10} xl={10}>
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            data-testid="dpoFirstNameInputGroup"
          >
            <FormInputGroup
              labelName={dpoInfo.dpo.firstName.labelName}
              inputName={dpoInfo.dpo.firstName.inputName}
              isLabelRequired={true}
              rules={dpoInfo.dpo.firstName.rules}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6} data-testid="dpoLastNameInputGroup">
            <FormInputGroup
              labelName={dpoInfo.dpo.lastName.labelName}
              inputName={dpoInfo.dpo.lastName.inputName}
              isLabelRequired={true}
              rules={dpoInfo.dpo.lastName.rules}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={6} data-testid="dpoEmailInputGroup">
          <FormInputGroup
            labelName={dpoInfo.dpo.email.labelName}
            inputName={dpoInfo.dpo.email.inputName}
            isLabelRequired={true}
            rules={dpoInfo.dpo.email.rules}
            icon={<MailOutline />}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <FormInputGroup
            labelName={dpoInfo.dpo.phone.labelName}
            inputName={dpoInfo.dpo.phone.inputName}
            icon={<LocalPhone />}
          />
        </Grid>

        <Grid item xs={12} className="McpButtons">
          <Grid>
            <LoadingButton
              loading={isUpdatingMerchantData}
              fullWidth
              variant="contained"
              color="primary"
              onClick={(e) => handleUpdateMerchant(e)}
            >
              {t("common.saveBtn")} <KeyboardArrowRight />
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DpoView
