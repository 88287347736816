import { Person2 } from "@mui/icons-material"
import { InfoOutlined } from "@mui/icons-material"
import { Alert, Grid, Typography } from "@mui/material"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

const ProjectCommentListView: FC<{
  projectData: ProjectRecord
  projectStatusAndCommentData: ProjectStatusChangeList
}> = ({ projectData, projectStatusAndCommentData }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "projectDetails"
  })

  const shouldRenderNoCommentsAlert =
    projectStatusAndCommentData.changelog.length === 0 ||
    (projectStatusAndCommentData.changelog.length === 1 &&
      !projectStatusAndCommentData.changelog[0].comment)

  return (
    <Grid
      container
      data-testid={"commentListView"}
      className="McpComments"
      rowSpacing={2}
    >
      {shouldRenderNoCommentsAlert && (
        <Grid item xs={12} data-testid="noCommentsAlert">
          <Alert severity="info">{t("commentsTab.noCommentsAlert")}</Alert>
        </Grid>
      )}

      {projectStatusAndCommentData.changelog.map((commentRecord) => {
        const commentDate = new Date(commentRecord.createdAt)

        if (commentRecord.comment) {
          return (
            <Grid
              container
              item
              key={commentRecord.projectStatusChangeId}
              xs={12}
            >
              <Grid item>
                <Person2 />
              </Grid>
              <Grid item>
                <Typography
                  variant="caption"
                  key={`${commentRecord.projectStatusChangeId}`}
                  data-testid="projectComment"
                >
                  {`${commentRecord.author} | ${commentDate.toLocaleString("de-DE")}`}
                </Typography>

                <Typography
                  variant="body1"
                  whiteSpace={"pre-wrap"}
                >{` ${commentRecord.comment}`}</Typography>
              </Grid>
            </Grid>
          )
        }
      })}

      {projectData.status !== "WAITING_FOR_MERCHANT" && (
        <Grid
          container
          item
          xs={12}
          className="McpInfoText"
          data-testid="McpNoCommentPossible"
        >
          <InfoOutlined fontSize="small" />
          <Typography variant="caption">
            {t("commentsTab.noCommentsInStatusAllowed")}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default ProjectCommentListView
