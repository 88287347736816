import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Grid, Tab } from "@mui/material"
import { SyntheticEvent, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { PasswordChangeView } from "src/features/account"

const AccountDataBox = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "accountPage.account-data"
  })

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (
      location.pathname.endsWith("account-data") &&
      !searchParams.get("tab")
    ) {
      searchParams.set("tab", "1")

      navigate(`/account/account-data?${searchParams.toString()}`)
    }
  }, [navigate, searchParams])

  const handleTabChange = (_event: SyntheticEvent, newTabPage: string) => {
    searchParams.set("tab", newTabPage)
    navigate(`/account/account-data?${searchParams.toString()}`)
  }

  return (
    <Grid xs={12} item className="McpTabs" margin={2} alignItems="flex-start">
      <TabContext value={searchParams.get("tab") ?? "1"}>
        <Box className="McpTabsContext">
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons={false}
            className="borderBottom"
          >
            <Tab label={t("changePasswordTab.title")} value="1" />
          </TabList>
        </Box>
        <TabPanel value="1" className="McpSummaryView">
          <PasswordChangeView />
        </TabPanel>
      </TabContext>
    </Grid>
  )
}

export default AccountDataBox
