import {
  KeyboardArrowRightRounded,
  Visibility,
  VisibilityOff
} from "@mui/icons-material"
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography
} from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  authenticationService,
  UpdatePasswordParameters
} from "src/shared/authentication"
import {
  checkPasswordConditions,
  checkPasswordStrength,
  generateSecurePassword
} from "src/shared/functions"
import {
  passwordConditionStrings,
  passwordHints,
  passwordStrengthTerms
} from "src/shared/variables"

const PasswordChangeView = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "accountPage.account-data.changePasswordTab"
  })
  const { enqueueSnackbar } = useSnackbar()

  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [repeatNewPassword, setRepeatNewPassword] = useState("")
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false)
  const [passwordsEqualError, setPasswordsEqualError] = useState(false)
  const [repeatPasswordNotEqualError, setRepeatPasswordNotEqualError] =
    useState(false)
  const [hasClearedPasswordConditions, setHasClearedPasswordConditions] =
    useState(false)

  const [isFocused, setIsFocused] = useState(false)

  const { mutate: updatePassword, isPending: isUpdatingPassword } = useMutation<
    unknown,
    Error,
    UpdatePasswordParameters
  >({
    mutationFn: ({
      currentPassword: oldPassword,
      newPassword
    }: UpdatePasswordParameters) =>
      authenticationService.updatePassword({
        currentPassword: oldPassword,
        newPassword
      })
  })

  const handleChangePasswordFormSubmit = () => {
    updatePassword(
      { currentPassword: oldPassword, newPassword },
      {
        onSuccess: () => {
          setOldPassword("")
          setNewPassword("")
          setRepeatNewPassword("")
          setShowNewPassword(false)
          setShowOldPassword(false)
          setShowRepeatNewPassword(false)
          enqueueSnackbar({
            variant: "success",
            message: t("changePasswordSuccessSnackbar")
          })
        },
        onError: (error) => {
          enqueueSnackbar({
            variant: "detailedSnackbar",
            message: t(error.name, { keyPrefix: "errorCodes.auth" }),
            autoHideDuration: null,
            details: error.message
          })
        }
      }
    )
  }

  const passwordConditions = checkPasswordConditions(newPassword)
  const passwordStrength = checkPasswordStrength(
    newPassword,
    passwordConditions
  )

  const handleGeneratePassword = () => {
    const password = generateSecurePassword()
    setNewPassword(password)
    setRepeatNewPassword(password)

    setShowNewPassword(true)
    setShowRepeatNewPassword(true)
  }

  useEffect(() => {
    if (
      repeatNewPassword.length > 0 &&
      newPassword.length > 0 &&
      repeatNewPassword !== newPassword
    ) {
      setRepeatPasswordNotEqualError(true)
    } else {
      setRepeatPasswordNotEqualError(false)
    }

    const clearedPasswordConditions =
      Object.values(passwordConditions).filter(
        (condition) => condition === true
      ).length === 6

    setHasClearedPasswordConditions(clearedPasswordConditions)
  }, [repeatNewPassword, newPassword, passwordConditions])

  useEffect(() => {
    if (
      oldPassword.length > 0 &&
      newPassword.length > 0 &&
      newPassword === oldPassword
    ) {
      setPasswordsEqualError(true)
    } else {
      setPasswordsEqualError(false)
    }
  }, [oldPassword, newPassword])

  return (
    <Box
      className="McpForm"
      component="form"
      onSubmit={(e) => {
        e.preventDefault()
        handleChangePasswordFormSubmit()
      }}
    >
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined">
            <FormLabel required>{t("oldPassword")}</FormLabel>
            <OutlinedInput
              data-testid="oldPasswordInput"
              value={oldPassword}
              size="small"
              required
              name="oldPassword"
              type={showOldPassword ? "text" : "password"}
              id="oldPassword"
              onChange={(e) => setOldPassword(e.target.value)}
              autoComplete="old-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t("togglePasswordVisibility", {
                      keyPrefix: "authentication"
                    })}
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    edge="end"
                  >
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} lg={6}>
            <FormControl variant="outlined">
              <FormLabel required>{t("newPassword")}</FormLabel>
              <OutlinedInput
                data-testid="newPasswordInput"
                value={newPassword}
                size="small"
                required
                name="newPassword"
                type={showNewPassword ? "text" : "password"}
                id="newPassword"
                onChange={(e) => setNewPassword(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                autoComplete="new-password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t("togglePasswordVisibility", {
                        keyPrefix: "authentication"
                      })}
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            {passwordsEqualError && (
              <small
                data-testid="alertEqualPasswords"
                className="McpErrorMessage"
              >
                {t("equalPasswords")}
              </small>
            )}

            {newPassword.length > 0 && (
              <Box className="McpPasswordStrength">
                <Box className="McpPasswordStrengthScala">
                  <Box
                    sx={{ left: `${passwordStrength.percentage.toFixed(2)}%` }}
                  ></Box>
                </Box>
                <Box className="McpPasswordStrengthText">
                  {passwordStrength.readableValue >= 1 &&
                  passwordStrength.readableValue <= 5
                    ? t(
                        `${passwordStrengthTerms[passwordStrength.readableValue]}`,
                        {
                          keyPrefix: "authentication.passwordStrength"
                        }
                      )
                    : t("passwordStrengthUnknown", {
                        keyPrefix: "authentication.passwordStrength"
                      })}
                </Box>
              </Box>
            )}
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormControl variant="outlined">
              <FormLabel required>{t("repeatNewPassword")}</FormLabel>
              <OutlinedInput
                value={repeatNewPassword}
                data-testid="repeatNewPasswordInput"
                size="small"
                required
                name="repeatNewPassword"
                type={showRepeatNewPassword ? "text" : "password"}
                id="password"
                onChange={(e) => setRepeatNewPassword(e.target.value)}
                autoComplete="new-password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t("togglePasswordVisibility", {
                        keyPrefix: "authentication"
                      })}
                      onClick={() =>
                        setShowRepeatNewPassword(!showRepeatNewPassword)
                      }
                      edge="end"
                    >
                      {showRepeatNewPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            {repeatPasswordNotEqualError && (
              <small
                data-testid="alertNotEqualPasswords"
                className="McpErrorMessage"
              >
                {t("notEqualRepeatPassword")}
              </small>
            )}
          </Grid>

          {isFocused && (
            <Grid container item>
              <Grid
                container
                columnSpacing={2}
                className="McpPasswordConditions"
                justifyContent={"space-between"}
              >
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                  columnSpacing={2}
                  rowSpacing={2}
                >
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      {t("passwordRequirements", {
                        keyPrefix: "authentication.passwordStrength"
                      })}
                    </Typography>
                  </Grid>

                  <Grid item container rowSpacing={1} columnSpacing={2} xs={12}>
                    {passwordConditionStrings.map((condition) => (
                      <Grid
                        item
                        container
                        xs={"auto"}
                        className={`McpPasswordCondition ${passwordConditions[condition] ? "isValid" : "isInvalid"}`}
                        key={condition}
                      >
                        <Box className="icon"></Box>
                        <Typography variant="body1">
                          {t(condition, {
                            keyPrefix: "authentication.passwordStrength"
                          })}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>

                  <Grid item container rowSpacing={1} columnSpacing={2} xs={12}>
                    {(passwordStrength.hasAlphabeticalOrder ||
                      passwordStrength.hasKeyboardPattern ||
                      passwordStrength.hasSequentialNumbers) && (
                      <Grid item xs={12} mt={2}>
                        <Typography variant="subtitle2">
                          {t("passwordHints", {
                            keyPrefix: "authentication.passwordStrength"
                          })}
                        </Typography>
                      </Grid>
                    )}

                    <Grid
                      item
                      container
                      rowSpacing={1}
                      columnSpacing={2}
                      xs={12}
                    >
                      {passwordHints
                        .filter((hint) => passwordStrength[hint])
                        .map((hint) => (
                          <Grid
                            item
                            xs={12}
                            className="McpPasswordCondition McpPasswordConditionNote isInvalid"
                            key={hint}
                          >
                            <Box className="icon"></Box>
                            <Typography variant="body1">
                              {t(hint, {
                                keyPrefix: "authentication.passwordStrength"
                              })}
                            </Typography>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid container item className="McpButtons">
            <Button
              data-testid="changePasswordBtn"
              type="submit"
              variant="contained"
              disabled={
                isUpdatingPassword ||
                repeatPasswordNotEqualError ||
                passwordsEqualError ||
                oldPassword === "" ||
                newPassword === "" ||
                repeatNewPassword === "" ||
                !hasClearedPasswordConditions
              }
            >
              {t("saveBtn", { keyPrefix: "common" })}
              <KeyboardArrowRightRounded fontSize="small" />
            </Button>
            <Button
              variant="outlined"
              className="McpGeneratePassword"
              onClick={handleGeneratePassword}
              size="small"
            >
              {t("generatePassword", {
                keyPrefix: "authentication"
              })}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PasswordChangeView
