import {
  KeyboardArrowRight,
  LocalPhone,
  MailOutline,
  Print,
  Public
} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Box, Grid } from "@mui/material"
import { useAtom } from "jotai"
import { useTranslation } from "react-i18next"
import { FormInputGroup } from "src/features/onboarding"
import { updateMerchantDataAtom } from "src/shared/stores"

const ContactView = ({
  handleUpdateMerchant
}: {
  handleUpdateMerchant: (e: React.BaseSyntheticEvent) => Promise<void>
}) => {
  const { t } = useTranslation("translation")
  const [{ isPending: isUpdatingMerchantData }] = useAtom(
    updateMerchantDataAtom
  )

  const contactInfo = {
    phone: {
      inputName: "phone",
      labelName: t("onboardingPage.phone"),
      rules: {
        required: t("onboardingPage.merchantFormData.phoneRequiredErrorMessage")
      }
    },
    fax: {
      inputName: "fax",
      labelName: t("accountPage.company.contactTab.fax")
    },
    homePage: {
      inputName: "homePage",
      labelName: t("onboardingPage.merchantFormData.homePage"),
      rules: {
        required: t(
          "onboardingPage.merchantFormData.homePageRequiredErrorMessage"
        )
      }
    },
    email: {
      inputName: "email",
      labelName: t("onboardingPage.email"),
      rules: {
        required: t("onboardingPage.emailRequiredErrorMessage")
      }
    }
  }

  return (
    <Box data-testid="merchantContactContainer" className="McpForm">
      <Grid container item spacing={2}>
        <Grid item xs={12} lg={6} data-testid="merchantPhoneInputGroup">
          <FormInputGroup
            inputName={contactInfo.phone.inputName}
            labelName={contactInfo.phone.labelName}
            isLabelRequired={true}
            rules={contactInfo.phone.rules}
            icon={<LocalPhone />}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormInputGroup
            inputName={contactInfo.fax.inputName}
            labelName={contactInfo.fax.labelName}
            icon={<Print />}
          />
        </Grid>

        <Grid item xs={12} lg={6} data-testid="merchantHomePageInputGroup">
          <FormInputGroup
            inputName={contactInfo.homePage.inputName}
            labelName={contactInfo.homePage.labelName}
            isLabelRequired={true}
            rules={contactInfo.homePage.rules}
            icon={<Public />}
          />
        </Grid>

        <Grid item xs={12} lg={6} data-testid="merchantEmailInputGroup">
          <FormInputGroup
            inputName={contactInfo.email.inputName}
            labelName={contactInfo.email.labelName}
            isLabelRequired={true}
            rules={contactInfo.email.rules}
            icon={<MailOutline />}
          />
        </Grid>

        <Grid item xs={12} className="McpButtons">
          <Grid>
            <LoadingButton
              loading={isUpdatingMerchantData}
              fullWidth
              variant="contained"
              color="primary"
              onClick={(e) => handleUpdateMerchant(e)}
            >
              {t("common.saveBtn")} <KeyboardArrowRight />
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContactView
