import { atom } from "jotai"
import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query"
import { ApiError, get as getClient, put as putClient } from "src/shared/client"

export const projectDetailsTabAtom = atom<string>("1")

export const projectIdAtom = atom<string | undefined>(undefined)

export const projectDataAtom = atomWithQuery<ProjectRecord, ApiError>((get) => {
  const projectId = get(projectIdAtom)

  return {
    queryKey: ["projectData", projectId],
    queryFn: async () => {
      const response = await (
        await getClient({
          path: `/project-service/projects/${projectId}`
        })
      ).json()

      return response
    },
    retry: false,
    enabled: !!projectId,
    staleTime: Infinity,
    refetchOnWindowFocus: false
  }
})

export const updateProjectDataAtom = atomWithMutation<
  ProjectRecord,
  ProjectRecord,
  ApiError
>((get) => {
  const projectId = get(projectIdAtom)

  return {
    mutationFn: async (updatedProjectData) => {
      const response = await (
        await putClient({
          path: `/project-service/projects/${projectId}`,
          body: updatedProjectData
        })
      ).json()

      return response
    },
    retry: false,
    staleTime: Infinity,
    refetchOnWindowFocus: false
  }
})
