const hasSequentialNumbers = (password: string): boolean => {
  const ascending = "01234567890"
  const descending = "09876543210"

  // Check for at least 3 consecutive digits
  for (let i = 0; i < ascending.length - 2; i++) {
    const seq = ascending.substring(i, i + 3)
    if (password.includes(seq)) {
      return true // Return true if a consecutive sequence is found
    }
  }

  for (let i = 0; i < descending.length - 2; i++) {
    const seq = descending.substring(i, i + 3)
    if (password.includes(seq)) {
      return true // Return true if a consecutive sequence is found
    }
  }

  return false // Return false if no sequence is found
}

const hasAlphabeticalOrder = (password: string): boolean => {
  const alphabets = "abcdefghijklmnopqrstuvwxyz"
  const reversedAlphabets = alphabets.split("").reverse().join("")

  // Function to check for at least 3 consecutive letters
  const hasSequence = (str: string): boolean => {
    for (let i = 0; i < str.length - 2; i++) {
      const seq = str.substring(i, i + 3) // Create a substring of 3 characters
      const regex = new RegExp(seq, "i") // Create a regex that ignores case
      if (regex.test(password)) {
        return true // Return true if a consecutive sequence is found
      }
    }
    return false // Return false if no sequence is found
  }

  return hasSequence(alphabets) || hasSequence(reversedAlphabets)
}

const hasKeyboardPattern = (password: string): boolean => {
  const keyboardPatterns = [
    "qwertzuiopü",
    "üpoiuztrewq",
    "qwertyuiop",
    "poiuytrewq",
    "asdfghjklöä",
    "äölkjhgfdsa",
    "asdfghjkl",
    "lkjhgfdsa",
    "yxcvbnm",
    "mnbvcxy",
    "zxcvbnm",
    "mnbvcxz"
  ]

  return keyboardPatterns.some((pattern) => {
    for (let i = 0; i <= pattern.length - 3; i++) {
      // Check for at least 3 consecutive characters
      const substring = pattern.slice(i, i + 3)
      if (password.includes(substring)) {
        return true // Return true if at least 3 characters are found
      }
    }
    return false // Return false if no 3-character sequence is found
  })
}

export const checkPasswordStrength = (
  password: string,
  conditions: PasswordConditions
): {
  score: number
  percentage: number
  readableValue: PasswordStrengthLevel
  hasSequentialNumbers?: boolean
  hasAlphabeticalOrder?: boolean
  hasKeyboardPattern?: boolean
} => {
  let score = 0

  // Points for fulfilled conditions
  for (const condition in conditions) {
    if (conditions[condition as keyof PasswordConditions]) {
      score += 1
    }
  }

  // Define length tiers and their corresponding points
  const lengthTiers = [
    { minLength: 10, points: 1 },
    { minLength: 12, points: 1 },
    { minLength: 14, points: 1 },
    { minLength: 16, points: 1 }
  ]

  // Calculate points based on password length
  const length = password.length
  for (const tier of lengthTiers) {
    if (length >= tier.minLength) {
      score += tier.points
    }
  }

  // Check for invalid sequences
  const hasSequentialNumbersResult = hasSequentialNumbers(password)
  const hasAlphabeticalOrderResult = hasAlphabeticalOrder(password)
  const hasKeyboardPatternResult = hasKeyboardPattern(password)

  // Deduct points for invalid sequences
  if (hasSequentialNumbersResult) score -= 1 // -1 for sequential numbers
  if (hasAlphabeticalOrderResult) score -= 1 // -1 for alphabetical order
  if (hasKeyboardPatternResult) score -= 1 // -1 for keyboard patterns

  // Calculate maximum score dynamically
  const maxConditionScore = Object.keys(conditions).length // Number of conditions
  const maxLengthScore = lengthTiers.reduce(
    (total, tier) => total + tier.points,
    0
  ) // Total points for length tiers
  const maxScore = maxConditionScore + maxLengthScore // Total max score

  // Calculate percentage value
  const percentage = Math.max(0, Math.min((score / maxScore) * 100, 100)) // Percentage value between 0 and 100%

  // Determine the readable value based on the percentage
  let readableValue: PasswordStrengthLevel = 5

  switch (true) {
    case percentage > 90:
      readableValue = 1
      break
    case percentage > 75:
      readableValue = 2
      break
    case percentage > 50:
      readableValue = 3
      break
    case percentage > 30:
      readableValue = 4
      break
  }

  // Return an object containing all the desired values
  const result: PasswordStrength = {
    score,
    percentage,
    readableValue, // Add the readable value to the return object
    hasSequentialNumbers: hasSequentialNumbersResult,
    hasAlphabeticalOrder: hasAlphabeticalOrderResult,
    hasKeyboardPattern: hasKeyboardPatternResult
  }

  return result
}
