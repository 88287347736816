import { createSearchParams } from "react-router-dom"

export const routes = {
  dashboard: "/",
  onboarding: "/onboarding",
  projects: {
    index: "/projects",
    nestedRoutes: {
      projectDetail: ":projectId"
    }
  },
  account: {
    index: "/account",
    nestedRoutes: {
      accountData: "account-data",
      company: "company",
      contact: "contact",
      bankAccount: "bank-account",
      legal: "legal",
      dpa: "dpa"
    },
    defaultRoute: "account-data"
  },
  signUp: "/register",
  verifyEmailAddress: "/verify-email-address",
  signIn: "/login",
  resetPassword: "/reset-password",
  changePassword: "/change-password",
  profile: "/profile",
  settings: "/settings",
  test: "/test"
}

export const buildVerifyEmailAddressPageUrl = ({
  emailAddress
}: {
  emailAddress: string
}) => {
  const searchParameters = createSearchParams({ emailAddress })

  return `${routes.verifyEmailAddress}/?${searchParameters}`
}

export const buildResetPasswordPageUrl = ({
  emailAddress
}: {
  emailAddress?: string
}) => {
  if (emailAddress) {
    const searchParameters = createSearchParams({ emailAddress })

    return `${routes.resetPassword}/?${searchParameters}`
  }

  return routes.resetPassword
}

export const buildChangePasswordPageUrl = ({
  emailAddress
}: {
  emailAddress: string
}) => {
  const searchParameters = createSearchParams({ emailAddress })

  return `${routes.changePassword}/?${searchParameters}`
}

export const buildProjectDetailPageUrl = (projectId: string) =>
  `${routes.projects.index}/${projectId}`

export const buildAccountSubpageUrl = (subpage: AccountSubpages) =>
  `${routes.account.index}/${routes.account.nestedRoutes[subpage]}`
