import { ErrorOutline, KeyboardArrowRight } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Box, Grid } from "@mui/material"
import { useAtom } from "jotai"
import { useTranslation } from "react-i18next"
import { FormInputGroup, FormSelectGroup } from "src/features/onboarding"
import { useI18nCountries } from "src/shared/i18n"
import { updateMerchantDataAtom } from "src/shared/stores"

const AddressView = ({
  handleUpdateMerchant
}: {
  handleUpdateMerchant: (e: React.BaseSyntheticEvent) => Promise<void>
}) => {
  const { t } = useTranslation("translation")
  const [{ isPending: isUpdatingMerchantData }] = useAtom(
    updateMerchantDataAtom
  )

  const { possibleCountries } = useI18nCountries()

  const addressInfo = {
    street: {
      inputName: "address.street",
      labelName: t("onboardingPage.merchantFormData.street"),
      rules: {
        required: t(
          "onboardingPage.merchantFormData.streetRequiredErrorMessage"
        )
      }
    },
    houseNumber: {
      inputName: "address.houseNumber",
      labelName: t("onboardingPage.merchantFormData.houseNumber")
    },
    addressAddition: {
      inputName: "address.addressAddition",
      labelName: t("onboardingPage.merchantFormData.addressAddition")
    },
    zip: {
      inputName: "address.zip",
      labelName: t("onboardingPage.merchantFormData.zip"),
      rules: {
        required: t("onboardingPage.merchantFormData.zipRequiredErrorMessage")
      }
    },
    city: {
      inputName: "address.city",
      labelName: t("onboardingPage.merchantFormData.city"),
      rules: {
        required: t("onboardingPage.merchantFormData.cityRequiredErrorMessage")
      }
    },
    state: {
      inputName: "address.state",
      labelName: t("onboardingPage.merchantFormData.state")
    },
    country: {
      inputName: "address.country",
      labelName: t("onboardingPage.merchantFormData.country")
    }
  }

  return (
    <Box data-testid="merchantAddressContainer" className="McpForm">
      <Grid container item spacing={2}>
        <Grid item xs={12} lg={8} data-testid="merchantStreetInputGroup">
          <FormInputGroup
            inputName={addressInfo.street.inputName}
            labelName={addressInfo.street.labelName}
            isLabelRequired={true}
            rules={addressInfo.street.rules}
          />
        </Grid>
        <Grid item xs={12} lg={4} data-testid="merchantHouseNumberInputGroup">
          <FormInputGroup
            inputName={addressInfo.houseNumber.inputName}
            labelName={addressInfo.houseNumber.labelName}
          />
        </Grid>

        <Grid item xs={12}>
          <FormInputGroup
            inputName={addressInfo.addressAddition.inputName}
            labelName={addressInfo.addressAddition.labelName}
          />
        </Grid>

        <Grid item xs={12} lg={4} data-testid="merchantZipInputGroup">
          <FormInputGroup
            inputName={addressInfo.zip.inputName}
            labelName={addressInfo.zip.labelName}
            isLabelRequired={true}
            rules={addressInfo.zip.rules}
          />
        </Grid>
        <Grid item xs={12} lg={8} data-testid="merchantCityInputGroup">
          <FormInputGroup
            inputName={addressInfo.city.inputName}
            labelName={addressInfo.city.labelName}
            isLabelRequired={true}
            rules={addressInfo.city.rules}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormInputGroup
            inputName={addressInfo.state.inputName}
            labelName={addressInfo.state.labelName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSelectGroup
            disabled={true}
            countryFlags={true}
            labelName={addressInfo.country.labelName}
            inputName={addressInfo.country.inputName}
            selectItems={possibleCountries()}
          />
          <Box className="McpInfoText">
            <ErrorOutline fontSize="small" />
            {t("accountPage.disabledInputInfoText")}
          </Box>
        </Grid>

        <Grid item xs={12} className="McpButtons">
          <Grid>
            <LoadingButton
              loading={isUpdatingMerchantData}
              fullWidth
              variant="contained"
              color="primary"
              onClick={(e) => handleUpdateMerchant(e)}
            >
              {t("common.saveBtn")} <KeyboardArrowRight />
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddressView
