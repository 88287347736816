import { ErrorOutline } from "@mui/icons-material"
import { Alert as MUIAlert } from "@mui/material"
import { ReactNode } from "react"
import useAlertConfig from "src/shared/components/api/useAlertConfig"

const Alert = ({
  status,
  children
}: {
  status?: OnboardingStatus | ProjectStatus

  children?: ReactNode
}) => {
  const { alertColor, alertIcon } = useAlertConfig()
  return (
    <MUIAlert
      color={status ? alertColor[status] : "info"}
      icon={status ? alertIcon[status] : <ErrorOutline />}
    >
      {children}
    </MUIAlert>
  )
}

export default Alert
