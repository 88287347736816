import { ChipOwnProps } from "@mui/material"
import { useTranslation } from "react-i18next"

type LabelProps = {
  onboardings?: string
  merchants?: string
  projects?: string
}

const useChipConfig = () => {
  const { t } = useTranslation("translation", { keyPrefix: "chip" })
  const chipLabel: Record<ProjectStatus | ProjectActivityStatus, LabelProps> = {
    APPROVED: {
      projects: t("approved")
    },
    DECLINED: {
      projects: t("declined")
    },
    READY_FOR_APPROVAL: { projects: t("reviewed") },
    WAITING_FOR_MERCHANT: { projects: t("resubmitted") },
    ACTIVE: {
      projects: t("active")
    },
    INACTIVE: {
      projects: t("inactive")
    }
  }

  const CHIP_DEFAULT_TEXT = t("defaultText")

  const chipColor: Record<
    ProjectStatus | ProjectActivityStatus,
    ChipOwnProps["color"]
  > = {
    APPROVED: "success",
    DECLINED: "error",
    READY_FOR_APPROVAL: "info",
    WAITING_FOR_MERCHANT: "warning",
    ACTIVE: "success",
    INACTIVE: "default"
  }
  return { chipLabel, chipColor, CHIP_DEFAULT_TEXT }
}

export default useChipConfig
