import { Check, Close } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material"
import { useAtom } from "jotai"
import type { FC } from "react"
import { useTranslation } from "react-i18next"
import {
  projectDataAtom,
  projectListDataAtom,
  updateProjectDataAtom
} from "src/shared/stores"

type ProjectActivityConfirmationDialogProps = {
  handleActivityStatusChange: (newActivityStatus: ProjectActivityStatus) => void
  isDialogOpen: boolean
  setIsDialogOpen: (newState: boolean) => void
  newActivityStatus: ProjectActivityStatus
  isChecked: boolean
  setIsChecked: (newsState: boolean) => void
}

const ProjectActivityConfirmationDialog: FC<
  ProjectActivityConfirmationDialogProps
> = ({
  isDialogOpen,
  setIsDialogOpen,
  newActivityStatus,
  handleActivityStatusChange,
  isChecked,
  setIsChecked
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "projectDetails"
  })

  const [{ isFetching: isFetchingProjectData }] = useAtom(projectDataAtom)
  const [{ isPending: isUpdatingProjectData }] = useAtom(updateProjectDataAtom)
  const [{ isFetching: isFetchingProjectListData }] =
    useAtom(projectListDataAtom)

  return (
    <Dialog open={isDialogOpen} disableScrollLock>
      <DialogTitle id="alert-dialog-title">
        {t(`activityConfirmationDialog.${newActivityStatus}.dialogTitle`)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
          {t(
            `activityConfirmationDialog.${newActivityStatus}.dialogDescriptionText`
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box className="McpButtons">
          <Button
            color="error"
            data-testid="dialog-cancel-button"
            disabled={
              isFetchingProjectData ||
              isUpdatingProjectData ||
              isFetchingProjectListData
            }
            onClick={() => {
              setIsDialogOpen(!isDialogOpen)
              setIsChecked(!isChecked)
            }}
            autoFocus
            startIcon={<Close />}
          >
            {t("cancelBtn", { keyPrefix: "common" })}
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            data-testid="dialog-submit-button"
            loading={
              isFetchingProjectData ||
              isUpdatingProjectData ||
              isFetchingProjectListData
            }
            onClick={() => {
              handleActivityStatusChange(newActivityStatus)
            }}
            startIcon={<Check />}
          >
            {t(`activityConfirmationDialog.${newActivityStatus}.confirmBtn`)}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ProjectActivityConfirmationDialog
