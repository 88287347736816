import { ErrorMessage } from "@hookform/error-message"
import { Box, FormLabel, InputAdornment, TextField } from "@mui/material"
import type { BaseSyntheticEvent } from "react"
import { Controller, RegisterOptions, useFormContext } from "react-hook-form"
import { normalizeWhitespace } from "src/shared/functions"

type FormInputGroupProps = {
  defaultValue?: string
  disabled?: boolean
  placeholder?: string
  inputName: string
  isLabelRequired?: boolean
  labelName: string
  rules?: RegisterOptions
  icon?: React.ReactElement
  onInput?: (value: string) => string
}

const FormInputGroup = ({
  defaultValue,
  disabled,
  placeholder,
  inputName,
  isLabelRequired = false,
  labelName,
  rules,
  icon,
  onInput
}: FormInputGroupProps) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  return (
    <>
      <Controller
        defaultValue={defaultValue ?? null}
        name={inputName}
        control={control}
        rules={rules}
        render={({ field: { value, onChange, onBlur, ref } }) => (
          <>
            <FormLabel required={isLabelRequired}>{labelName}</FormLabel>
            <TextField
              className={disabled ? "disabledInput" : ""}
              disabled={disabled}
              placeholder={placeholder}
              ref={ref}
              size="small"
              type="text"
              onBlur={(e) => {
                const normalizedValue = normalizeWhitespace(e.target.value)
                onChange(normalizedValue)
                onBlur()
              }}
              InputProps={{
                startAdornment: icon ? (
                  <InputAdornment position="start">{icon}</InputAdornment>
                ) : null
              }}
              value={value ?? ""}
              onChange={(e: BaseSyntheticEvent) => {
                if (onInput) {
                  e.preventDefault()
                  e.target.value = onInput(e.target.value)
                  onChange(e)
                } else {
                  onChange(e)
                }
              }}
            />
          </>
        )}
      />
      <ErrorMessage
        errors={errors}
        name={inputName}
        render={({ message }) => (
          <Box
            component="span"
            data-testid="errorMessage"
            role="alert"
            className="McpErrorMessage"
          >
            {message}
          </Box>
        )}
      />
    </>
  )
}

export default FormInputGroup
