import { checkPasswordStrength } from "./checkPasswordStrength"

const generateRandomCharacter = (characters: string) =>
  characters.charAt(Math.floor(Math.random() * characters.length))

export const generateSecurePassword = (): string => {
  const lowercase = "abcdefghijklmnopqrstuvwxyz"
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  const numbers = "0123456789"
  const specialCharacters = "!@#$%^&*()-_=+[]{}:,.?"

  const passwordLength = 24 // Setze die gewünschte Passwortlänge

  let password = ""

  // Stelle sicher, dass das Passwort mindestens ein Zeichen aus jeder Kategorie enthält
  password += generateRandomCharacter(lowercase)
  password += generateRandomCharacter(uppercase)
  password += generateRandomCharacter(numbers)
  password += generateRandomCharacter(specialCharacters)

  // Fülle den Rest des Passworts mit zufälligen Zeichen
  const allCharacters = lowercase + uppercase + numbers + specialCharacters
  for (let i = 4; i < passwordLength; i++) {
    password += generateRandomCharacter(allCharacters)
  }

  // Mische das Passwort
  password = password
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("")

  // Prüfe die Passwortstärke und Bedingungen
  const conditions: PasswordConditions = {
    specialCharacter: true, // Annahme: da wir mindestens ein Sonderzeichen haben
    number: true, // Annahme: da wir mindestens eine Zahl haben
    minCharacters: password.length >= 8, // Stelle sicher, dass das Passwort mindestens 8 Zeichen hat
    uppercase: /[A-Z]/.test(password), // Überprüfe, ob mindestens ein Großbuchstabe vorhanden ist
    lowercase: /[a-z]/.test(password), // Überprüfe, ob mindestens ein Kleinbuchstabe vorhanden ist
    whitespace: /^[\S]+.*[\S]+$/.test(password)
  }

  const { hasSequentialNumbers, hasAlphabeticalOrder, hasKeyboardPattern } =
    checkPasswordStrength(password, conditions)

  // Generiere ein neues Passwort, wenn eine der unerwünschten Bedingungen zutrifft
  if (hasSequentialNumbers || hasAlphabeticalOrder || hasKeyboardPattern) {
    return generateSecurePassword() // Rekursiver Aufruf, um ein neues Passwort zu generieren
  }

  return password
}
