import { Amplify } from "aws-amplify"

const setupCognito = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
        userPoolClientId: import.meta.env.VITE_COGNITO_USER_POOL_WEB_CLIENT_ID
      }
    }
  })
}

export default setupCognito
