import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query"
import {
  ApiError,
  get as getClient,
  post as postClient
} from "src/shared/client"
import { userAtom } from "src/shared/stores"

type PostOnboardingStatusChangeRequest = Pick<
  OnboardingStatusChangeRecord,
  "comment" | "author" | "status"
>

export const onboardingStatusChangeListAtom = atomWithQuery<
  OnboardingStatusChangeList,
  ApiError
>((get) => {
  const { data: user } = get(userAtom)

  return {
    queryKey: ["onboardingStates"],
    queryFn: async () => {
      const response = await (
        await getClient({
          path: `/merchant-service/onboardings/${user?.sub}/states`
        })
      ).json()

      return response
    },
    retry: false,
    enabled: !!user?.sub,
    staleTime: Infinity,
    refetchOnWindowFocus: false
  }
})

export const postOnboardingStatusChangeAtom = atomWithMutation<
  unknown,
  PostOnboardingStatusChangeRequest,
  ApiError
>((get) => {
  const { data: user } = get(userAtom)

  return {
    mutationFn: async (formData) => {
      const response = await postClient({
        path: `/merchant-service/onboardings/${user?.sub}/states`,
        body: formData
      })
      return response
    },
    retry: false,
    staleTime: Infinity,
    refetchOnWindowFocus: false
  }
})
