import {
  Add,
  DeleteForever,
  ErrorOutline,
  KeyboardArrowRight
} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  FormLabel,
  Grid,
  TextField,
  Typography
} from "@mui/material"
import { useAtom } from "jotai"
import { useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormInputGroup, FormSelectGroup } from "src/features/onboarding"
import { updateMerchantDataAtom } from "src/shared/stores"

type CeoView = {
  merchantData?: MerchantRecord
  handleUpdateMerchant: (e: React.BaseSyntheticEvent) => Promise<void>
}

const CeoView = ({ handleUpdateMerchant, merchantData }: CeoView) => {
  const { t } = useTranslation("translation")
  const [{ isPending: isUpdatingMerchantData }] = useAtom(
    updateMerchantDataAtom
  )
  const {
    fields: ceoDeputies,
    append,
    remove
  } = useFieldArray({
    name: "ceoDeputies"
  })

  const getPerson = (personObject?: Person) =>
    personObject
      ? `${personObject.title === "MR" ? t("onboardingPage.maleSalutation") : t("onboardingPage.femaleSalutation")} ${personObject.firstName} ${personObject.lastName}`
      : "~"

  const managementInfo = {
    ceo: {
      value: getPerson(merchantData?.ceo),
      labelName: t("onboardingPage.merchantFormData.ceo")
    },
    deputiesCeo: {
      labelName: t("onboardingPage.merchantFormData.ceoDeputies"),
      title: {
        labelName: t("onboardingPage.title"),
        possibleSalutations: [
          {
            text: t("common.defaultSelect"),
            value: ""
          },
          {
            value: "MR",
            text: t("onboardingPage.maleSalutation")
          },
          {
            value: "MRS",
            text: t("onboardingPage.femaleSalutation")
          }
        ],
        rules: {
          required: t("onboardingPage.titleRequiredErrorMessage")
        }
      },
      firstName: {
        labelName: t("onboardingPage.firstName"),
        rules: {
          required: t("onboardingPage.firstNameRequiredErrorMessage")
        }
      },
      lastName: {
        labelName: t("onboardingPage.lastName"),
        rules: {
          required: t("onboardingPage.lastNameRequiredErrorMessage")
        }
      }
    }
  }

  return (
    <Box data-testid="merchantCeoContainer" className="McpForm">
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <FormLabel>{managementInfo.ceo.labelName}</FormLabel>
          <TextField
            className="disabledInput"
            disabled
            size="small"
            type="text"
            value={managementInfo.ceo.value}
          />

          <Box className="McpInfoText">
            <ErrorOutline fontSize="small" />
            {t("accountPage.disabledInputInfoText")}
          </Box>
        </Grid>

        <Grid container item xs={12} className="McpDeputiesBox">
          <Grid item xs={12} mb={1}>
            <Typography>{managementInfo.deputiesCeo.labelName}</Typography>
          </Grid>
          {ceoDeputies.map((ceoDeputy, index) => (
            <Grid
              container
              item
              xs={12}
              spacing={2}
              key={ceoDeputy.id}
              className="McpDeputiesContainer"
              data-testid={`deputy_ceo_group_${index}`}
            >
              <Grid item xs={12} md={2}>
                <FormSelectGroup
                  isLabelRequired={true}
                  labelName={managementInfo.deputiesCeo.title.labelName}
                  inputName={`ceoDeputies.${index}.title`}
                  selectItems={
                    managementInfo.deputiesCeo.title.possibleSalutations
                  }
                  rules={managementInfo.deputiesCeo.title.rules}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <FormInputGroup
                  placeholder={t("onboardingPage.firstNamePlaceholder")}
                  isLabelRequired={true}
                  labelName={managementInfo.deputiesCeo.firstName.labelName}
                  inputName={`ceoDeputies.${index}.firstName`}
                  rules={managementInfo.deputiesCeo.firstName.rules}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <FormInputGroup
                  placeholder={t("onboardingPage.lastNamePlaceholder")}
                  isLabelRequired={true}
                  labelName={managementInfo.deputiesCeo.lastName.labelName}
                  inputName={`ceoDeputies.${index}.lastName`}
                  rules={managementInfo.deputiesCeo.lastName.rules}
                />
              </Grid>
              <Grid item xs={12} className="deleteRow">
                <Button
                  data-testid={`removeDeputyBtn${index}`}
                  size="small"
                  variant="contained"
                  color="error"
                  startIcon={<DeleteForever />}
                  aria-label="remove"
                  onClick={() => {
                    remove(index)
                  }}
                >
                  <Box component="span" className="deleteText">
                    {t("remove", { keyPrefix: "common" })}
                  </Box>
                </Button>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12} textAlign="center">
            <Button
              data-testid="addDeputyBtn"
              size="small"
              color="primary"
              aria-label="add"
              variant="outlined"
              startIcon={<Add />}
              onClick={() => {
                append(
                  {
                    title: "MR",
                    firstName: "",
                    lastName: "",
                    email: null,
                    phone: null
                  },
                  { shouldFocus: false }
                )
              }}
            >
              {t("onboardingPage.merchantFormData.addDeputy")}
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} className="McpButtons">
          <Grid>
            <LoadingButton
              loading={isUpdatingMerchantData}
              fullWidth
              variant="contained"
              color="primary"
              onClick={(e) => handleUpdateMerchant(e)}
            >
              {t("common.saveBtn")} <KeyboardArrowRight />
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CeoView
