import { authenticationService } from "src/shared/authentication"

const rootPath = import.meta.env.VITE_API_BASE_URL
type HttpMethod = "GET" | "POST" | "PUT" | "DELETE"

type HttpRequestParams = {
  path: string
  method: HttpMethod
  body?: unknown
  headers?: Record<string, string>
  signal?: AbortSignal
}

export class ApiError extends Error {
  public readonly errorResponse: Response
  constructor(message: string, errorResponse: Response) {
    super(message)
    this.errorResponse = errorResponse
  }
}

const callWithMethod = async ({
  path,
  method,
  body,
  headers = {},
  signal
}: HttpRequestParams): Promise<Response> => {
  const bearerToken = `Bearer ${await authenticationService.getJwtToken()}`
  const options: RequestInit = {
    method,
    headers: {
      Authorization: bearerToken,
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers
    },
    signal
  }
  if (method !== "GET") {
    options.body = JSON.stringify(body)
  }

  const response = await fetch(`${rootPath}${path}`, options)

  if (!response.ok) {
    throw new ApiError(
      `Request failed with status ${response.status}`,
      response
    )
  }
  return response
}

export const get = ({
  path,
  headers,
  signal
}: Pick<HttpRequestParams, "path" | "headers" | "signal">): Promise<Response> =>
  callWithMethod({ path, method: "GET", headers, signal })

export const put = ({
  path,
  body,
  signal
}: Pick<HttpRequestParams, "path" | "body" | "signal">): Promise<Response> =>
  callWithMethod({ path, method: "PUT", body, signal })

export const post = ({
  path,
  body,
  signal
}: Pick<HttpRequestParams, "path" | "body" | "signal">): Promise<Response> =>
  callWithMethod({ path, method: "POST", body, signal })

export const deleteFetch = ({
  path,
  signal
}: Pick<HttpRequestParams, "path" | "signal">): Promise<Response> =>
  callWithMethod({ path, method: "DELETE", signal })
