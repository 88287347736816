// Import custom fonts
import "src/assets/css/styles.css"

import { createTheme } from "@mui/material/styles"

// adjust to MCP colors
const theme = createTheme({
  shape: {
    borderRadius: 4
  },
  spacing: 8,
  palette: {
    primary: {
      main: "rgba( 94, 161, 136, 1)",
      contrastText: "rgba(255,255,255,1)"
    },
    secondary: {
      main: "#00dc7a",
      contrastText: "rgba(255,255,255,1)"
    },
    background: {
      default: "rgba(255,255,255,1)",
      paper: "rgba(255,255,255,1)"
    },
    text: {
      primary: "rgba(0,0,0,0.8)"
    },
    error: {
      main: "#D32F2F",
      contrastText: "#EBFFF0"
    },
    warning: {
      main: "#F59F56",
      dark: "#b85d10",
      contrastText: "#fff8f2"
    },
    info: {
      main: "#014361",
      dark: "#0e3445",
      contrastText: "#fff"
    },
    success: {
      main: "rgba( 94, 161, 136, 1)",
      contrastText: "rgba(255,255,255,1)"
    },

    divider: "rgba(0,0,0,0.016)"
  },
  typography: {
    fontFamily: "Inter",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: "1.8rem",
      fontWeight: 700
    },
    h2: {
      fontSize: "1.2rem",
      fontWeight: 600
    },
    h3: {
      fontSize: "2rem"
    },
    h4: {
      fontSize: ".8rem",
      fontWeight: 600
    },
    h5: {
      fontSize: ".8rem",
      fontWeight: 800
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 600
    },
    subtitle1: {
      fontSize: "1.2rem"
    },
    subtitle2: {
      fontSize: "0.88rem",
      fontWeight: "600",
      display: "inline-flex"
    },
    body1: {
      fontSize: "0.88rem"
    },
    body2: {
      fontSize: "0.88rem"
    },
    caption: {
      fontSize: ".8rem",
      fontWeight: "300",
      color: "rgba(0,0,0,0.6)",
      width: "100%"
    },
    button: {
      textTransform: "none"
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          borderRadius: 4,
          borderWidth: "1px",
          borderColor: "rgba(0,0,0,0.072)",
          borderStyle: "solid"
        }
      }
    },
    // Mui Tabs
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiTabs-indicator": {
            backgroundColor: "rgba( 94, 161, 136, 1 )"
          }
        }
      }
    },
    // App Bar / Nav
    MuiAppBar: {
      styleOverrides: {
        root: {
          padding: "0 1.5rem",
          "& .MuiToolbar-root": {
            height: "100%",
            minHeight: "0",
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
            "& button": {
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              fontSize: "14px",
              color: "rgba(0,0,0,0.8)",
              gap: "0.5rem",
              "& svg": {
                height: "20px",
                width: "20px",
                padding: 0
              }
            }
          }
        }
      }
    },
    // Labels
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: "500",
          color: "rgba(0,0,0,0.6)",
          width: "100%",
          display: "block",
          paddingBottom: "0.32rem"
        },
        asterisk: {
          color: "#FF6E6C"
        }
      }
    },
    // Outlined Input
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: "100%",
          "& input": {
            fontSize: "0.82rem"
          },
          "& textarea": {
            fontSize: "0.82rem"
          },
          "&.MuiInputBase-adornedStart": {
            paddingLeft: "0px",
            "& .MuiInputAdornment-root": {
              borderRadius: "4px 0 0 4px"
            }
          },
          "&.MuiInputBase-adornedEnd": {
            paddingRight: "0px",
            "& .MuiInputAdornment-root": {
              borderRadius: "0 4px 4px 0",
              padding: "0",
              margin: "0"
            }
          },
          "& .MuiInputAdornment-root": {
            backgroundColor: "rgba(94, 161, 136, 1)",
            width: "40px",
            minWidth: "40px",
            height: "40px",
            maxHeight: "40px",
            justifyContent: "center",
            textAlign: "center",
            "& svg": {
              width: "20px",
              height: "20px",
              margin: "0 auto",
              fill: "rgba(225, 255, 160, 1)"
            },
            "& button": {
              width: "40px",
              minWidth: "40px",
              height: "40px",
              maxHeight: "40px",
              margin: "0"
            }
          },
          "& .MuiInputAdornment-root.InputAdornmentText": {
            width: "auto",
            minWidth: "auto",
            padding: "0 1rem",
            margin: "0"
          }
        }
      }
    },
    // Input
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "1rem",
          borderColor: "#A3A3A3",
          padding: "0.5rem 1rem",
          "&.Mui-focused": {
            borderColor: "yellow"
          }
        }
      }
    },
    // Stepper
    MuiStepper: {
      styleOverrides: {
        horizontal: {
          margin: "1.5rem auto"
        }
      }
    },
    // Stepper Icon
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-completed": {
            color: "#2E7D32"
          }
        }
      }
    },
    // Form Control
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
          display: "block",
          "&.MuiInputBaseWidthAuto-root": {
            background: "red",
            width: "100%",
            maxWidth: "300px",
            display: "inline-flex"
          }
        }
      }
    },
    // Form Control Label
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          "& .MuiTypography-root": {
            width: "auto",
            display: "inline-block"
          }
        },
        asterisk: {
          color: "#FF6E6C"
        }
      }
    },
    // Link
    MuiLink: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignContent: "center",
          alignItems: "center",
          gap: "0.5rem",
          textDecoration: "none",
          "&:hover": {
            color: "rgba(0,0,0,1)",
            textDecoration: "underline"
          }
        }
      }
    },
    // Divider
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: "1.5rem 0",
          borderColor: "rgba(0,0,0,0.1)"
        }
      }
    },
    // Chip
    MuiChip: {
      styleOverrides: {
        root: {
          height: "1.5rem",
          "&.MuiChip-filledSuccess": {
            backgroundColor: "rgba(94, 161, 136, 1)",
            color: "rgba(225, 255, 160, 1)"
          },
          "& .MuiChip-label": {
            fontSize: ".64rem"
          }
        }
      }
    },
    // Button
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          padding: "4px",
          fontSize: "8px",
          "&.Mui-focusVisible span": {
            borderRadius: "4px"
          },
          "&.Mui-focusVisible.MuiIconButton-edgeStart span": {
            borderRadius: "4px 0 0 4px"
          },
          "&.Mui-focusVisible.MuiIconButton-edgeEnd span": {
            borderRadius: "0 4px 4px 0"
          }
        }
      }
    },
    // Drawer
    MuiDrawer: {
      styleOverrides: {
        root: {},
        paper: {
          display: "flex",
          padding: "1.5rem",
          flexDirection: "column",
          gap: "1.5rem"
        }
      }
    },
    // Switch
    MuiSwitch: {
      styleOverrides: {
        root: {
          height: "28px",
          "& .MuiButtonBase-root": {
            top: "2px",
            padding: "4px",
            left: "6px"
          },
          "& .MuiSwitch-thumb ": {
            height: "16px",
            width: "16px"
          }
        }
      }
    },
    // MuiAlert
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: "center",
          padding: "2px 8px",
          "& svg": {
            fontSize: "1.2rem !important"
          }
        }
      }
    },
    // Menu
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: "0",
          "& li": {
            gap: ".5rem"
          }
        }
      }
    },
    // Select
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: "0.88rem"
        }
      }
    }
  }
})
export default theme
