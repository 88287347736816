import { useAtom } from "jotai"
import { lazy, Suspense, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { LoadingContainer } from "src/shared/components"
import { routes } from "src/shared/routing"
import { onboardingDataAtom } from "src/shared/stores"

const OnboardingPage = lazy(() => import("./OnboardingPage"))

const OnboardingPageContainer = () => {
  const navigate = useNavigate()
  const [{ data: onboardingData, isLoading: isLoadingOnboardingData }] =
    useAtom(onboardingDataAtom)

  useEffect(() => {
    if (
      !isLoadingOnboardingData &&
      onboardingData?.status !== "IN_PROGRESS" &&
      onboardingData?.status !== "WAITING_FOR_MERCHANT" &&
      onboardingData?.status !== "NOT_YET_STARTED"
    ) {
      navigate(routes.dashboard)
    }
  }, [isLoadingOnboardingData, navigate, onboardingData?.status])

  return (
    <Suspense
      fallback={<LoadingContainer data-testid="onboarding-suspense-progress" />}
    >
      <OnboardingPage />
    </Suspense>
  )
}

export default OnboardingPageContainer
